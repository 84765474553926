import request from '@/utils/request'

// 用户登录
export function getListData(data) {
    return request({
        url: '/door/searchTeamUsertList',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 成员详情
export function getDetail(id) {
    return request({
        url: `/door/teamUserInfo/${id}`,
        headers: {
            isToken: false
        },
        method: 'get',
    })
}
