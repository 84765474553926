<template>
  <div id="contentMain" class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 通用顶部 -->
    <generalTop></generalTop>

    <!-- 中间区域 -->
    <div class="center-wrap">
      <!-- 面包屑区 -->
      <div class="breadcrumb-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>专业团队</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="team-content-wrap">
        <div class="title-wrap">
          <span>我&nbsp;们&nbsp;的&nbsp;专&nbsp;业&nbsp;团&nbsp;队</span>
          <div class="title-line"></div>
        </div>

        <div class="team-wrap">
          <div class="team-item" v-for="item in teamData" :key="item.id">
            <div class="team-info-wrap">
              <div class="top">
                <div class="intro-wrap">
                  <div class="name-wrap">
                    <span>{{ item.name }}</span>
                    <span>{{ item.post }}</span>
                  </div>

                  <div class="skill">
                    <span>擅长领域：{{ item.area }}</span>
                  </div>
                </div>

                <div class="logo">
                  <img src="../../assets/images/logo-color.png" alt="" />
                </div>
              </div>

              <div class="bottom" @click="gotoDetail(item.id)">
                <div class="more-wrap">
                  <span>了解更多</span>
                  <div class="circle">
                    <i class="iconfont icon-gengduo1"></i>
                  </div>
                </div>
                <div class="more-line"></div>
              </div>
            </div>

            <div class="picture">
              <img :src="serverURL + item.img" alt="" />
            </div>
          </div>

          <!-- 分页 -->
          <div class="pagination-wrap">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="totalCount"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import generalTop from "../../components/GeneralTop/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { getListData, getDetail } from "@/api/team";
import global from "@/utils/global.js";

export default {
  components: {
    generalTop,
    footbar,
    sidebar,
  },

  data() {
    return {
      //   页面标题
      textTitle: "专业团队",

      //   团队数据
      teamData: [],

      // 总条数
      totalCount: 0,

      // 每页条数
      pageSize: 6,

      // 当前页
      currentPage: 1,

      // 服务器路径
      serverURL: global.httpUrl,
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    // 获取列表数据
    getData() {
      const data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      getListData(data).then((res) => {
        // console.log(res);
        this.totalCount = res.total;
        this.teamData = res.data;
      });
    },

    // 查看详情
    gotoDetail(id) {
      getDetail(id).then((res) => {
        this.$router.push({
          path: "/Common/detail",
          query: { id, type: "team", content: res.data },
        });
      });
    },

    // 分页
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.getData();

      contentMain.scrollIntoView();
    },
  },
};
</script>

<style scoped>
.team-content-wrap {
  width: 100%;
  padding: 0 20px;
}

.title-wrap {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-wrap span {
  display: block;
  font-size: 36px;
  font-family: Adobe Heiti Std;
  font-weight: 600;
  color: #000000;
}

.title-line {
  margin-top: 5px;
  width: 72px;
  height: 6px;
  background: #ff8516;
  border-radius: 3px;
}

.team-wrap {
  margin: 60px auto 0 78px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.team-item {
  position: relative;
  margin-bottom: 72px;
  width: 455px;
  height: 286px;
  background: #ffffff;
}

.team-info-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #282828;
  border-radius: 10px;
  overflow: hidden;
}

.team-item .team-info-wrap .top {
  position: relative;
  width: 100%;
  height: 183px;
  background-color: #fff;
}

.team-item .team-info-wrap .top .intro-wrap {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-30%, -50%);
  width: 320px;
}

.team-item .team-info-wrap .top .intro-wrap .name-wrap {
  display: flex;
  align-items: flex-end;
}

.team-item .team-info-wrap .top .intro-wrap .name-wrap span:nth-child(1) {
  font-size: 30px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
}

.team-item .team-info-wrap .top .intro-wrap .name-wrap span:nth-child(2) {
  margin-left: 40px;
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
}

.team-item .team-info-wrap .top .intro-wrap .skill {
  margin-top: 10px;
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
}

.team-item .team-info-wrap .top .intro-wrap .skill span {
  display: block;
  width: 100%;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.team-item .team-info-wrap .top .logo {
  position: absolute;
  top: 23px;
  right: 21px;
  width: 50px;
  height: 50px;
}

.team-item .team-info-wrap .top .logo img {
  width: 100%;
  height: 100%;
}

.team-item .picture {
  position: absolute;
  top: -39px;
  left: -86px;
  width: 177px;
  height: 325px;
  z-index: 1;
}

.team-item .picture img {
  width: 100%;
  height: 100%;
}

.team-item .team-info-wrap .bottom {
  position: relative;
  width: 100%;
  height: 103px;
  background-color: #282828;
  cursor: pointer;
}

.team-item .team-info-wrap .bottom .more-wrap {
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
  width: 122px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-item .team-info-wrap .bottom .more-wrap span {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.circle .iconfont {
  padding-left: 3px;
  font-size: 14px;
  color: #282828;
}

.more-line {
  position: absolute;
  right: 22px;
  bottom: 24px;
  width: 328px;
  height: 2px;
  background: #ffffff;
}
</style>
